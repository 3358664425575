import React from "react";
import PropTypes from "prop-types";
import Layout from "ps-components/Layout/Layout.jsx";
import errorPageStyle from "assets/jss/material-kit-pro-react/views/errorPageStyles.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Phone from "@material-ui/icons/Phone";
import { whiteColor } from "assets/jss/material-kit-pro-react.jsx";
import { Helmet } from "react-helmet";

const NotFoundPage = ({ classes }) => (
  <Layout small={true} noParallax={true}>
    <Helmet key="helmetTags">
    <title>Page Not Found | Star Health</title>
    </Helmet>
    <div className={classes.pageHeader}>
      <div>
        <GridContainer
          style={{
            position: `absolute`,

            zIndex: `3`,

            color: whiteColor,
            padding: `0 15px`,

            maxWidth: `880px`
          }}
          className={classes.postionfix}
        >
          <GridItem>
            <h2 className={classes.subTitle}>
              Sorry. Looks like this page is missing. But, we can still help you
              find the best insurance cover for you and your family
            </h2>
            <h4 className={classes.description}>
              <Phone className={classes.icon} />
              <span> 044 4674 5800</span>
            </h4>
          </GridItem>
        </GridContainer>
      </div>
      {/* </div> */}
    </div>
  </Layout>
);
NotFoundPage.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(errorPageStyle)(NotFoundPage);
